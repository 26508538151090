<template>
  <div class="custome-editor" v-html="contents"></div>
</template>

<script>
export default {
  props: {
    contents: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
.custome-editor {
  width: 100%;
  word-wrap: break-word;
  img {
    max-width: 100%;
    display: block;
  }
}
</style>
